@import "chota.min.css";

:root {
  --color-primary: #2a1d50;
  --grid-maxWidth: 138rem;
  --bd-primary: --color-priary;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Painting .card {
    padding: 0;
}
